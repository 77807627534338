
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "underwriter-settlement-offers",
  components: {},
  props: {
    redirect: String,
    policySummary: Object,
  },
  data() {
    return {
      offers: [{} as any],
    };
  },
  setup() {
    const { getSettlementOfferStatus, displayDatatable } = ResusableFunctions();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Settlement Offers", ["Clients", "Claims"]);
    });

    return { getSettlementOfferStatus, displayDatatable };
  },
  created() {
    this.getSettlmentOffers();

    //Set page title
    document.title =
      "Underwriter: Settlement Offers | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    // Get list of settlement offfers from all underwriters
    getSettlmentOffers() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("/underwriter/settlement-offers")
          .then(({ data }) => {
            // Assign data to variables
            this.offers = data.data;

            if (this.offers) {
              this.displayDatatable(["basicExample"]);
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
});
